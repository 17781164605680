import {h} from 'preact'
import PropTypes from 'prop-types'
import cn from 'classnames'
import {Link} from 'react-router-dom'

import './Logo.scss'
import logoImg from '../../assets/images/logo.svg'
import logoSecondImg from '../../assets/images/logo-second.svg'
import {handleScroll} from '../../utils/helpers'
import {ROUTE, LINK} from '../../utils/constants'

Logo.propTypes = {
  isFaq: PropTypes.bool,
  second: PropTypes.bool,
  onClose: PropTypes.func,
}

export default function Logo({isFaq, second, onClose}) {
  const LinkComponent = isFaq ? Link : 'a'
  return (
    <LinkComponent
      class="Logo"
      href={isFaq ? undefined : `#${LINK.home}`}
      to={isFaq ? ROUTE.home : undefined}
      onClick={isFaq ? onClose : handleScroll(LINK.home, onClose)}
    >
      <img
        width={second ? 95 : 150}
        height={second ? 37 : 58}
        src={second ? logoSecondImg : logoImg}
        alt="Video Profile Mapping"
      />
    </LinkComponent>
  )
}
