import {h, Fragment} from 'preact'
import {useMediaQuery} from 'react-responsive'
import SVG from 'react-inlinesvg'
import Swiper from 'react-id-swiper'

import './About.scss'
import arcSvg from '../assets/images/arc.svg'
import {Row, Col} from './common/grid'
import Animate, {EFFECT} from './common/Animate'
import {BREAKPOINT, DURATION, LINK} from '../utils/constants'

const icons = {
  cloud: require('../assets/icons/cloud.svg').default,
  certificate: require('../assets/icons/certificate.svg').default,
  book: require('../assets/icons/book.svg').default,
  play: require('../assets/icons/play.svg').default,
  armchair: require('../assets/icons/armchair.svg').default,
}

const features = [
  {
    type: 'cloud',
    title: 'Get access to Video Profile Mapping API',
    text:
      'Integrate our extensive API into your workflow and monitor the results.',
  },
  {
    type: 'certificate',
    title: 'Get the white labeled course design/ URL',
    text: 'We upload your logo to make the course your own.',
  },
  {
    type: 'book',
    title: 'Purchase Video Profile Mapping course',
    text:
      'Purchase the course for as many job seekers as you like. Wholesale discounts available.',
  },
  {
    type: 'play',
    title: 'Ask job seekers to complete the course',
    text:
      'After completing the course, the candidate will get a unique, beautifully presented CV page and a video presentation.',
  },
  {
    type: 'armchair',
    title: 'Increase chances for job seekers to gain employment',
    text:
      'Their own video presentation and detailed infographic CV highly increases their chances to get employed.',
  },
]

const swiper = {
  slideToClickedSlide: true,
  preventClicksPropagation: false,
  centeredSlides: true,
  grabCursor: true,
  speed: DURATION.longer,
  spaceBetween: 72,
  pagination: {
    el: '.Bullets',
    bulletElement: 'button',
    clickable: true,
  },
}

const renderItems = (animated) =>
  features.map(({type, title, text}, index) => {
    const content = (
      <>
        <div class="About-TitleBlock">
          <div class="About-Icon">
            <SVG src={icons[type]} />
          </div>
          <h3 class="About-Title">{title}</h3>
        </div>

        <p class="About-Text">{text}</p>
      </>
    )
    return animated ? (
      <Animate
        key={type}
        el="li"
        className={`About-Item About-Item_type_${type}`}
        effect={
          index === 1 ? EFFECT.left : index === 2 ? EFFECT.right : EFFECT.bottom
        }
      >
        {content}
      </Animate>
    ) : (
      <li key={type} class="About-Item">
        {content}
      </li>
    )
  })

export default function About() {
  const tablet = useMediaQuery({
    query: BREAKPOINT.tablet,
  })

  return (
    <section id={LINK.about} class="About Container">
      <Row>
        <Col xs={12} sm={6} smOffset={1} md={8}>
          <div class="About-Block">
            <h2 class="Title">About</h2>
            <Animate el="p" className="Subtitle" effect={EFFECT.bottom}>
              How it works
            </Animate>
            <Animate el="p" className="Text" effect={EFFECT.bottom}>
            Course participants will walk away with a profile page created to showcase who they are complemented with a high quality video pitch of themselves. The unique profile page is theirs to keep forever. An easy to follow step by step guide, no experience needed.
            </Animate>
          </div>
        </Col>
      </Row>
      {tablet ? (
        <Row>
          <Col xs={12} sm={5} smOffset={1}>
            <Animate effect={EFFECT.bottom}>
              <Swiper
                {...swiper}
                containerClass="About-Swiper Swiper"
                WrapperEl="ul"
              >
                {renderItems()}
              </Swiper>
            </Animate>
          </Col>
        </Row>
      ) : (
        <div class="About-Composition">
          <SVG class="About-Arc" src={arcSvg} />
          <ul>{renderItems(true)}</ul>
        </div>
      )}
    </section>
  )
}
