import {h} from 'preact'
import {createPortal} from 'preact/compat'
import PropTypes from 'prop-types'
import cn from 'classnames'
import {CSSTransition} from 'react-transition-group'
import SVG from 'react-inlinesvg'

import './Snackbar.scss'
import closeIcon from '../assets/icons/close.svg'
import {TRANSITION_TIMEOUT} from '../utils/constants'

const transitionClassNames = {
  enter: 'SnackbarEnter',
  enterActive: 'SnackbarEnter_active',
  exit: 'SnackbarExit',
  exitActive: 'SnackbarExit_active',
}

let timer = null

Snackbar.propTypes = {
  snackbar: PropTypes.object.isRequired,
  setSnackbar: PropTypes.func.isRequired,
}

export default function Snackbar({snackbar, setSnackbar}) {
  const handleSnackbarClose = () => {
    clearTimeout(timer)
    setSnackbar({...snackbar, open: false})
  }

  if (snackbar.open) {
    timer = setTimeout(handleSnackbarClose, 6000)
  }

  return createPortal(
    <CSSTransition
      unmountOnExit
      in={snackbar.open}
      classNames={transitionClassNames}
      timeout={TRANSITION_TIMEOUT}
    >
      <div class={cn('Snackbar', {Snackbar_error: snackbar.error})}>
        <p class="Snackbar-Text">{snackbar.text}</p>
        <button
          class="Snackbar-Button"
          aria-label="Close snackbar"
          onClick={handleSnackbarClose}
        >
          <SVG src={closeIcon} />
        </button>
      </div>
    </CSSTransition>,
    document.body
  )
}
