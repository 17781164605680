export const MOBILE = [
  /Android/i,
  /webOS/i,
  /iPhone/i,
  /iPad/i,
  /iPod/i,
  /BlackBerry/i,
  /Windows Phone/i,
].some((device) => navigator.userAgent.match(device))

export const ROUTE = {
  home: '/',
  faq: '/faq',
}

export const ANIMATION = {
  top: 'fadeInTop',
  right: 'fadeInRight',
  bottom: 'fadeInBottom',
  left: 'fadeInLeft',
  in: 'fadeInZoomIn',
  out: 'fadeInZoomOut',
}

export const BREAKPOINT = {
  mobile: '(max-width: 599px)',
  extra: '(max-width: 767px)',
  tablet: '(max-width: 959px)',
  desktop: '(min-width: 960px)',
}

export const DURATION = {
  shortest: 150,
  shorter: 200,
  short: 250,
  standard: 300,
  medium: 500,
  long: 800,
  longer: 1000,
  longest: 2000,
  autoplay: 4000,
}

export const LINK = {
  home: 'home',
  about: 'about',
  benefits: 'benefits',
  clients: 'clients',
  prices: 'prices',
  testimonials: 'testimonials',
  faq: 'faq',
  contact: 'contact',
  appStore: '',
  location: 'https://www.google.com/maps/place/Taupo',
  mail: 'mailto:hello@present.me',
  phone: 'tel:+64 21 426 344',
  terms: '/vpm-web-terms.html',
  sevenGlyphs: 'https://7glyphs.com',
}

export const BLANK_LINK_PROPS = {
  target: '_blank',
  rel: 'noopener noreferrer',
}

export const TRANSITION_TIMEOUT = {
  enter: DURATION.medium,
  exit: DURATION.standard,
}
