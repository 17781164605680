import {h, Fragment} from 'preact'
import {useMediaQuery} from 'react-responsive'
import Swiper from 'react-id-swiper'

import './Clients.scss'
import Animate, {EFFECT} from './common/Animate'
import {BREAKPOINT, DURATION, LINK} from '../utils/constants'

const clients = [
  {
    logo: [
      require('../assets/images/dgh.png').default,
      require('../assets/images/dgh@2x.png').default,
      require('../assets/images/dgh_sm.png').default,
      require('../assets/images/dgh_sm@2x.png').default,
    ],
    name: 'DGH LTD',
  },
  {
    logo: [
      require('../assets/images/social-development.png').default,
      require('../assets/images/social-development@2x.png').default,
      require('../assets/images/social-development_sm.png').default,
      require('../assets/images/social-development_sm@2x.png').default,
    ],
    name: 'Ministry of social development',
  },
]

const swiper = {
  slideToClickedSlide: true,
  preventClicksPropagation: false,
  centeredSlides: true,
  grabCursor: true,
  slidesPerView: 1.25,
  spaceBetween: 12,
  speed: DURATION.longer,
  pagination: {
    el: '.Bullets',
    bulletElement: 'button',
    clickable: true,
  },
}

const renderItems = (animated) =>
  clients.map(({name, logo}, index) => {
    const content = (
      <>
        <picture>
          <source
            media={BREAKPOINT.tablet}
            srcSet={`${logo[2]}, ${logo[3]} 2x`}
          />
          <img
            class="Clients-Image"
            width={188}
            height={120}
            src={logo[0]}
            srcSet={`${logo[1]} 2x`}
            alt={name}
          />
        </picture>
        <p class="Clients-Name">{name}</p>
      </>
    )
    return animated ? (
      <Animate
        key={name}
        el="li"
        className="Clients-Item"
        effect={EFFECT.bottom}
        delay={index * DURATION.shortest}
      >
        {content}
      </Animate>
    ) : (
      <li key={name} class="Clients-Item">
        {content}
      </li>
    )
  })

export default function Example() {
  const extra = useMediaQuery({
    query: BREAKPOINT.extra,
  })

  return (
    <section id={LINK.clients} class="Clients Container">
      <div class="Clients-Content">
        <h2 class="Title">Our clients</h2>
        <Animate
          el="p"
          className="Subtitle Subtitle_center"
          effect={EFFECT.bottom}
        >
          Proactive recruiting companies
        </Animate>
      </div>
      {extra ? (
        <Animate effect={EFFECT.bottom}>
          <Swiper
            {...swiper}
            containerClass="Clients-Swiper Swiper"
            WrapperEl="ul"
          >
            {renderItems()}
          </Swiper>
        </Animate>
      ) : (
        <ul class="Clients-List">{renderItems(true)}</ul>
      )}
    </section>
  )
}
