import {h, Fragment} from 'preact'
import {useMediaQuery} from 'react-responsive'

import './Benefits.scss'
import macbookImage from '../assets/images/macbook.png'
import macbookImage2x from '../assets/images/macbook@2x.png'
import macbookImageSm from '../assets/images/macbook_sm.png'
import macbookImageSm2x from '../assets/images/macbook_sm@2x.png'
import modulesImage from '../assets/images/modules.png'
import modulesImage2x from '../assets/images/modules@2x.png'
import modulesImageSm from '../assets/images/modules_sm.png'
import modulesImageSm2x from '../assets/images/modules_sm@2x.png'
import screensImage from '../assets/images/screens.png'
import screensImage2x from '../assets/images/screens@2x.png'
import screensImageSm from '../assets/images/screens_sm.png'
import screensImageSm2x from '../assets/images/screens_sm@2x.png'
import profileLinkImage from '../assets/images/profile-link.png'
import profileLinkImage2x from '../assets/images/profile-link@2x.png'
import profileLinkImageSm from '../assets/images/profile-link_sm.png'
import profileLinkImageSm2x from '../assets/images/profile-link_sm@2x.png'
import {Row, Col} from './common/grid'
import Animate, {EFFECT} from './common/Animate'
import {BREAKPOINT, LINK, BLANK_LINK_PROPS} from '../utils/constants'

export default function Benefits() {
  const mobile = useMediaQuery({
    query: BREAKPOINT.mobile,
  })

  const mobileLeftEffect = mobile ? EFFECT.bottom : EFFECT.left
  const mobileRightEffect = mobile ? EFFECT.bottom : EFFECT.right

  return (
    <section class="Benefits">
      <div class="Benefits-Container Container">
        <Row className="Benefits-Row">
          <Col xs={12} sm={4} smOffset={1} md={3} lg={3} lgOffset={2}>
            <div id={LINK.benefits} class="Benefits-Block">
              <h2 class="Title">Job seeker's benefits</h2>
              <Animate
                el="p"
                className="Benefits-Subtitle Subtitle"
                effect={mobileLeftEffect}
              >
                Video profile mapping course
              </Animate>
              <Animate
                el="p"
                className="Benefits-Text"
                effect={mobileLeftEffect}
              >
                This course will take you through a step by step guide to create the perfect summary of your life experience, work experience and the journey of how you got to where you are at today.
              </Animate>
            </div>
          </Col>
          <Col xs={12} sm={7} md={8} lg={6}>
            <Animate
              el="picture"
              className="Benefits-Example Benefits-Example_type_course"
              effect={mobileRightEffect}
            >
              <source
                media={BREAKPOINT.tablet}
                srcSet={`${macbookImageSm}, ${macbookImageSm2x} 2x`}
              />
              <img
                class="Benefits-Image"
                width={809}
                height={454}
                src={macbookImage}
                srcSet={`${macbookImage2x} 2x`}
                alt="Video profile mapping course"
              />
            </Animate>
          </Col>
        </Row>
        <Row className="Benefits-Row">
          <Col xs={12} sm={7} md={7} lg={6} lgOffset={1}>
            <Animate
              el="picture"
              className="Benefits-Example Benefits-Example_type_infographic"
              effect={mobileLeftEffect}
            >
              <source
                media={BREAKPOINT.tablet}
                srcSet={`${modulesImageSm}, ${modulesImageSm2x} 2x`}
              />
              <img
                class="Benefits-Image"
                width={603}
                height={519}
                src={modulesImage}
                srcSet={`${modulesImage2x} 2x`}
                alt="Personalised infographic"
              />
            </Animate>
          </Col>
          <Col xs={12} sm={4} smOffset={1} md={3} lg={3} lgOffset={1}>
            <div class="Benefits-Block">
              <Animate
                el="p"
                className="Benefits-Subtitle Subtitle"
                effect={mobileRightEffect}
              >
                Personalised infographic
              </Animate>
              <Animate
                el="p"
                className="Benefits-Text"
                effect={mobileRightEffect}
              >
                A beautifully designed personal profile page to showcase your skills and experience to potential employers and companies. To be used instead of your current CV.
              </Animate>
            </div>
          </Col>
        </Row>
        {!mobile && (
          <Fragment>
            <Row className="Benefits-Row">
              <Col xs={12} sm={4} smOffset={1} md={3} lg={3} lgOffset={2}>
                <div class="Benefits-Block">
                  <Animate
                    el="p"
                    className="Benefits-Subtitle Subtitle"
                    effect={mobileLeftEffect}
                  >
                    Video pitch
                  </Animate>
                  <Animate
                    el="p"
                    className="Benefits-Text"
                    effect={mobileLeftEffect}
                  >
                    A unique part of the course is the creation of a video pitch
                    using our app . The aim of the video is to showcase who you
                    are and what you are about, grab attention of companies
                    looking to recruit and create a memory or story they can
                    relate to.
                  </Animate>
                  <Animate
                    {...BLANK_LINK_PROPS}
                    el="a"
                    className="Benefits-Button Button Button_yellow"
                    href={LINK.appStore}
                    effect={EFFECT.zoomIn}
                  >
                    Download app
                  </Animate>
                </div>
              </Col>
              <Col xs={12} sm={7} md={8} lg={6}>
                <Animate
                  el="picture"
                  className="Benefits-Example"
                  effect={EFFECT.right}
                >
                  <source
                    media={BREAKPOINT.tablet}
                    srcSet={`${screensImageSm}, ${screensImageSm2x} 2x`}
                  />
                  <img
                    class="Benefits-Image"
                    width={637}
                    height={578}
                    src={screensImage}
                    srcSet={`${screensImage2x} 2x`}
                    alt="Video pitch"
                  />
                </Animate>
              </Col>
            </Row>
            <Row className="Benefits-Row" center="sm">
              <Col xs={12} sm={6} md={5} lg={4}>
                <div class="Benefits-CenterBlock">
                  <Animate
                    el="p"
                    className="Benefits-Subtitle Subtitle Subtitle_center"
                    effect={EFFECT.bottom}
                  >
                    Unique profile link
                  </Animate>
                  <Animate
                    el="p"
                    className="Benefits-Text"
                    effect={EFFECT.bottom}
                  >
                    By the end of this course you will have a unique link to your personalized profile page. This perfect summary and a short video pitch will give your future employer an insight into who you are and why you are the right person for the role.
                  </Animate>
                </div>
              </Col>
              <Col xs={12} md={10}>
                <Animate
                  el="picture"
                  className="Benefits-Example Benefits-Example_type_infographic"
                  effect={EFFECT.bottom}
                >
                  <source
                    media={BREAKPOINT.tablet}
                    srcSet={`${profileLinkImageSm}, ${profileLinkImageSm2x} 2x`}
                  />
                  <img
                    class="Benefits-Image"
                    width={1025}
                    height={465}
                    src={profileLinkImage}
                    srcSet={`${profileLinkImage2x} 2x`}
                    alt="Personalised infographic"
                  />
                </Animate>
              </Col>
            </Row>
          </Fragment>
        )}
      </div>
    </section>
  )
}
