import {h} from 'preact'
import {useMediaQuery} from 'react-responsive'
import ReactPlayer from 'react-player'

import './Example.scss'
import {Row, Col} from './common/grid'
import Animate, {EFFECT} from './common/Animate'
import {BREAKPOINT} from '../utils/constants'

export default function Example() {
  const tablet = useMediaQuery({
    query: BREAKPOINT.tablet,
  })

  const textEffect = tablet ? EFFECT.bottom : EFFECT.right
  return (
    <section class="Example Container">
      <Row className="Example-Row" center="xs">
        <Col xs={12} sm={8} md={7} lg={7}>
          <Animate
            className="Example-Video"
            effect={tablet ? EFFECT.zoomOut : EFFECT.left}
          >
            <ReactPlayer
              width="100%"
              height="100%"
              controls
              url="https://www.youtube.com/watch?v=nqsp9SjURG4"
              volume={1}
            />
          </Animate>
        </Col>
        <Col xs={12} sm={8} md={4} lg={3}>
          <div class="Example-Content">
            <h2 class="Title">Example</h2>
            <Animate
              el="p"
              className="Subtitle Subtitle_smCenter"
              effect={textEffect}
            >
              Bespoke video pitch
            </Animate>
            <Animate class="Example-Text" effect={textEffect}>
            The process starts with creating a short concise story of your experience, skills and personal timeline, and completes with recording yourself delivering that story.
            </Animate>
            <Animate class="Example-Text" effect={textEffect}>
            You get to tell your story in your own way and how you want it to be told. You want to make it personal to you, be yourself and share your qualities that make you the right person for the job!
            </Animate>
          </div>
        </Col>
      </Row>
    </section>
  )
}
