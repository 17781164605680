import {h, Fragment} from 'preact'
import {useState} from 'preact/hooks'

import './Contact.scss'
import Animate, {EFFECT} from './common/Animate'
import {Row, Col} from './common/grid'
import Form from './Form'
import Snackbar from './Snackbar'
import {LINK} from '../utils/constants'

export default function Contact() {
  const [snackbar, setSnackbar] = useState({
    open: false,
    error: false,
    text: '',
  })

  return (
    <>
      <section class="Contact">
        <div class="Container">
          <Row center="xs">
            <Col classes="Contact-Col" xs={12} sm={10} lg={8}>
              <Animate
                id={LINK.contact}
                className="Contact-Content"
                effect={EFFECT.bottom}
              >
                <div class="Contact-Block">
                  <h2 class="Contact-Title Title">Contact us</h2>
                  <p class="Contact-Subtitle Subtitle">
                  Enquire
                    <br />
                    today!
                  </p>
                  <p class="Contact-Text">
                    If you have any questions, just drop us a line. Our support
                    team is ready to help.
                  </p>
                </div>
                <Form setSnackbar={setSnackbar} />
              </Animate>
            </Col>
          </Row>
        </div>
      </section>
      <Snackbar snackbar={snackbar} setSnackbar={setSnackbar} />
    </>
  )
}
