import {h, Fragment} from 'preact'
import {useState} from 'preact/hooks'
import PropTypes from 'prop-types'
import {useMediaQuery} from 'react-responsive'

import './Panel.scss'
import menuIcon from '../assets/icons/menu.svg'
import Logo from './common/Logo'
import Navigation from './common/Navigation'
import IconButton from './common/IconButton'
import Drawer from './Drawer'
import {BREAKPOINT} from '../utils/constants'

Panel.propTypes = {
  isFaq: PropTypes.bool,
}

export default function Panel({isFaq}) {
  const [open, setOpen] = useState(false)
  const tablet = useMediaQuery({
    query: BREAKPOINT.tablet,
  })

  const handleOpen = () => setOpen(true)

  const handleClose = () => setOpen(false)

  return (
    <div class="Panel">
      <Logo isFaq={isFaq} />
      {tablet ? (
        <>
          <IconButton
            className="Panel-Button"
            label="Open drawer"
            icon={menuIcon}
            onClick={handleOpen}
          />
          <Drawer isFaq={isFaq} open={open} onClose={handleClose} />
        </>
      ) : (
        <Navigation isFaq={isFaq} />
      )}
    </div>
  )
}
