import {h} from 'preact'
import {useMediaQuery} from 'react-responsive'

import './Prices.scss'
import Animate, {EFFECT} from './common/Animate'
import {Row, Col} from './common/grid'
import {handleScroll} from '../utils/helpers'
import {BREAKPOINT, LINK} from '../utils/constants'

export default function Prices() {
  const mobile = useMediaQuery({
    query: BREAKPOINT.mobile,
  })

  return (
    <section id={LINK.prices} class="Prices">
      <div className="Prices-Container Container">
        <Row>
          <Col xs={12} sm={5} md={4} mdOffset={1}>
            <Animate
              className="Prices-Block"
              effect={mobile ? EFFECT.bottom : EFFECT.right}
            >
              {/* <h2 class="Prices-Title">$500 nzd per job seeker</h2>
              <p class="Prices-Subtitle">Wholesale pricing available</p> */}
              <p class="Prices-Text">
                Looking to use PresentMe to help your people? Get in touch to hear more.
              </p>
              <a
                class="Button Button_yellow"
                href={`#${LINK.contact}`}
                onClick={handleScroll(LINK.contact)}
              >
                Contact us
              </a>
            </Animate>
          </Col>
        </Row>
      </div>
    </section>
  )
}
