import {h} from 'preact'
import {useState} from 'preact/hooks'
import PropTypes from 'prop-types'
import axios from 'axios'
import SVG from 'react-inlinesvg'

import './Form.scss'
import loaderSvg from '../assets/images/loader.svg'

Form.propTypes = {
  setSnackbar: PropTypes.func.isRequired,
}

export default function Form({setSnackbar}) {
  const [values, setValues] = useState({})
  const [loading, setLoading] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    axios
      .post('https://api.present.me/api/mail/contact-us', values)
      .then(() => {
        setValues({})
        setSnackbar({open: true, text: 'Message has been sent!'})
        setLoading(false)
      })
      .catch(() => {
        setSnackbar({open: true, error: true, text: 'Something went wrong...'})
        setLoading(false)
      })
  }

  const handleChange = ({target}) =>
    setValues({...values, [target.name]: target.value})

  return loading ? (
    <div class="Form-Wrapper FadeIn">
      <SVG class="Form-Loader" src={loaderSvg} />
    </div>
  ) : (
    <form class="Form FadeIn" onSubmit={handleSubmit}>
      <fieldset>
        <label class="Form-Label">
          <span class="VisuallyHidden">Name</span>
          <input
            required
            name="name"
            value={values.name}
            type="text"
            placeholder="Name"
            onInput={handleChange}
          />
        </label>
        <label class="Form-Label">
          <span class="VisuallyHidden">Email</span>
          <input
            required
            name="email"
            value={values.email}
            type="email"
            placeholder="Email"
            onInput={handleChange}
          />
        </label>
        <label class="Form-Label">
          <span class="VisuallyHidden">Company name</span>
          <input
            required
            name="company"
            value={values.company}
            type="text"
            placeholder="Company name"
            onInput={handleChange}
          />
        </label>
        <label class="Form-Label">
          <span class="VisuallyHidden">Message</span>
          <textarea
            required
            value={values.message}
            name="message"
            type="text"
            placeholder="Message"
            rows={4}
            onInput={handleChange}
          />
        </label>
      </fieldset>
      <button class="Button Button_fullWidth" type="submit">
        Contact us
      </button>
    </form>
  )
}
