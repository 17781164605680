import scrollIntoView from 'smooth-scroll-into-view-if-needed'

import {DURATION} from './constants'

const scrollToSection = (e, to, onClose) => {
  e.preventDefault()
  scrollIntoView(document.getElementById(to), {
    behavior: 'smooth',
    duration: DURATION.longer,
    block: 'start',
  })
  onClose && onClose()
}

export const handleScroll = (to, onClose, isFaq) => (e) => {
  if (isFaq) return setTimeout(() => scrollToSection(e, to, onClose), 0)
  scrollToSection(e, to, onClose)
}
