import {h} from 'preact'
import PropTypes from 'prop-types'
import cn from 'classnames'
import SVG from 'react-inlinesvg'

import './IconButton.scss'

IconButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default function IconButton({className, label, icon, onClick}) {
  return (
    <button
      class={cn(className, 'IconButton')}
      aria-label={label}
      onClick={onClick}
    >
      <SVG src={icon} />
    </button>
  )
}
