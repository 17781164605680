import {h} from 'preact'
import {createPortal} from 'preact/compat'
import PropTypes from 'prop-types'
import {CSSTransition} from 'react-transition-group'

import './Drawer.scss'
import closeIcon from '../assets/icons/close.svg'
import Navigation from './common/Navigation'
import IconButton from './common/IconButton'
import Logo from './common/Logo'
import {TRANSITION_TIMEOUT} from '../utils/constants'

const transitionClassNames = {
  enter: 'DrawerEnter',
  enterActive: 'DrawerEnter_active',
  exit: 'DrawerExit',
  exitActive: 'DrawerExit_active',
}

Drawer.propTypes = {
  isFaq: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default function Drawer({isFaq, open, onClose}) {
  return createPortal(
    <CSSTransition
      unmountOnExit
      in={open}
      classNames={transitionClassNames}
      timeout={TRANSITION_TIMEOUT}
    >
      <aside class="Drawer">
        <IconButton
          class="Drawer-Button"
          label="Close drawer"
          icon={closeIcon}
          onClick={onClose}
        />
        <div class="Drawer-Content">
          <Logo second isFaq={isFaq} onClose={onClose} />
          <Navigation drawer isFaq={isFaq} onClose={onClose} />
        </div>
      </aside>
    </CSSTransition>,
    document.body
  )
}
