import {h, Fragment} from 'preact'
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom'
import {useEffect} from 'preact/hooks'

import Header from './Header'
import Faq from './Faq'
import About from './About'
import Benefits from './Benefits'
import Example from './Example'
import Clients from './Clients'
import Prices from './Prices'
import Testimonials from './Testimonials'
import Contact from './Contact'
import Footer from './Footer'
import {ROUTE} from '../utils/constants'

const Home = () => (
  <>
    <main>
      <About />
      <Benefits />
      <Example />
      {/* <Clients /> */}
      <Prices />
      <Testimonials />
      <Contact />
    </main>
    <Footer />
  </>
)

export default function App() {
  useEffect(() => {
    const preloader = document.getElementById('preloader')
    preloader.classList.remove('preloader-show')
    setTimeout(() => {
      preloader.remove()
    }, 300)
  }, [])

  return (
    <BrowserRouter>
      <Header />
      <Switch>
        <Route exact path={ROUTE.home} component={Home} />
        <Route exact path={ROUTE.faq} component={Faq} />
        <Redirect to={ROUTE.home} />
      </Switch>
    </BrowserRouter>
  )
}
