import {h} from 'preact'
import Swiper from 'react-id-swiper'

import './Testimonials.scss'
import Animate, {EFFECT} from './common/Animate'
import {Row, Col} from './common/grid'
import {DURATION, LINK} from '../utils/constants'

const testimonials = [
  {
    title: 'highly recommend it',
    text:
      'I\'m glad to be one of the first few people to take advantage of the vpm app it was a great way for me to get a good job at Hamilton City Council, when good jobs are so hard to come by. I didn\'t think it would lead anywhere and I was hesitant to do it. But after just going through the course and making the video. It was actually pretty easy and now I have this App for life and can use it for personal use or future job applications. Highly recommend it!',
  },
  // {
  //   title: 'new way presentation 2',
  //   text:
  //     'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.',
  // },
  // {
  //   title: 'new way presentation 3',
  //   text:
  //     'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.',
  // },
  // {
  //   title: 'new way presentation 4',
  //   text:
  //     'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.',
  // },
]

const swiper = {
  slideToClickedSlide: true,
  preventClicksPropagation: false,
  centeredSlides: true,
  grabCursor: true,
  speed: DURATION.longer,
  spaceBetween: 72,
  pagination: {
    el: '.Bullets',
    bulletElement: 'button',
    clickable: true,
  },
}

export default function Testimonials() {
  return (
    <section id={LINK.testimonials} class="Testimonials">
      <div className="Testimonials-Container Container">
        <Row>
          <Col xs={12} sm={6} smOffset={6} md={4} lg={3} lgOffset={7}>
            <h2 class="Title">Job seekers testimonials</h2>
            <Animate effect={EFFECT.bottom}>
              <Swiper
                {...swiper}
                containerClass="Testimonials-Swiper Swiper"
                WrapperEl="ul"
              >
                {testimonials.map(({title, text}) => (
                  <li key={title}>
                    <h3 class="Testimonials-Title Subtitle Subtitle_center">
                      “{title}”
                    </h3>
                    <p class="Testimonials-Text">{text}</p>
                  </li>
                ))}
              </Swiper>
            </Animate>
          </Col>
        </Row>
      </div>
    </section>
  )
}
