import {h} from 'preact'
import {useState} from 'preact/hooks'
import PropTypes from 'prop-types'
import cn from 'classnames'
import {Waypoint} from 'react-waypoint'

import './Animate.scss'
import {DURATION} from '../../utils/constants'

export const EFFECT = {
  top: 'fadeInTop',
  right: 'fadeInRight',
  bottom: 'fadeInBottom',
  left: 'fadeInLeft',
  zoomIn: 'fadeInZoomIn',
  zoomOut: 'fadeInZoomOut',
}

Animate.proptypes = {
  children: PropTypes.node,
  el: PropTypes.string,
  className: PropTypes.string,
  animate: PropTypes.bool,
  effect: PropTypes.string.isRequired,
  duration: PropTypes.number,
  delay: PropTypes.number,
  easing: PropTypes.string,
}

export default function Animate({
  children,
  el: Element = 'div',
  className,
  animate,
  effect,
  duration = DURATION.longer,
  delay = 0,
  easing = 'cubic-bezier(0.4, 0, 0.2, 1)',
  ...props
}) {
  const [reached, setReached] = useState(false)

  const onWaypointEnter = () => !reached && setReached(true)

  return (
    <Waypoint bottomOffset="5%" onEnter={onWaypointEnter}>
      <Element
        {...props}
        class={cn(className, 'Animate')}
        style={
          reached
            ? {
                animation: `${effect} ${duration}ms ${easing} ${delay}ms both`,
              }
            : undefined
        }
      >
        {children}
      </Element>
    </Waypoint>
  )
}
