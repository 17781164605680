if (process.env.NODE_ENV === 'development') {
  require('preact/debug')
}
import {h, render} from 'preact'

import './styles/index.scss'
import App from './components/App'

import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-N2LKPTF'
}

TagManager.initialize(tagManagerArgs)

render(<App />, document.getElementById('app'))
